<template>
	<edit-template @confirm="submit" @cancel="$confirm('取消新增?', '提示', { type: 'warning' }).then(() => $router.back())" :cancelRemind="false" confirmText="提交" cancelText="取消">

    <div class="form-body" style="padding-top: 60rem; padding-left: 40rem">
      <el-form ref="form" :model="formData" :rules="rules" label-width="140rem">
        <el-form-item label="报名学校" prop="school_id">
          <el-select v-model="formData.school_id" :popper-append-to-body="false" placeholder="请选择报名学校" @change="changeSchool">
            <el-option v-for="item in selectConfig.school_data" :key="item.school_id" :label="item.school_name" :value="item.school_id">
          </el-option></el-select>
        </el-form-item>
        <el-form-item label="入学年级" prop="grade_name">
          <el-select v-model="formData.grade_name" :popper-append-to-body="false" placeholder="请选择入学年级">
            <el-option v-for="(item,index) of selectConfig.grade_data" :key="index" :label="item" :value="item">
          </el-option></el-select>
        </el-form-item>
        <el-form-item label="入学年份" prop="grade_year">
          <el-select v-model="formData.grade_year" :popper-append-to-body="false" placeholder="请选择入学年份">
            <el-option v-for="(item,index) of selectConfig.grade_year_data" :key="index" :label="item+''" :value="item+''">
          </el-option></el-select>
        </el-form-item>
        <el-form-item label="学生类型" prop="student_type">
          <el-select v-model="formData.student_type" :popper-append-to-body="false" placeholder="请选择学生类型" @change="studentChange">
            <el-option v-for="item in selectConfig.student_type_add" :key="item.student_type" :label="item.student_type_name" :value="item.student_type">
          </el-option></el-select>
        </el-form-item>
        <el-form-item label="学生姓名" prop="student_name">
          <el-input v-model="formData.student_name" maxlength="14" placeholder="请输入学生姓名"></el-input>
        </el-form-item>
        <el-form-item label="学生身份证号" prop="student_idcard">
          <el-input v-model="formData.student_idcard" maxlength="18" placeholder="请输入学生身份证号"></el-input>
        </el-form-item>
        <el-form-item label="是否为培优生" prop="is_training">
          <el-select v-model="formData.is_training">
            <el-option :value="0" label="否"></el-option>
            <el-option :value="1" label="是"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学习方向" prop="study_type">
          <el-select v-model="formData.study_type" :popper-append-to-body="false" placeholder="请选择学习方向" @change="()=>formData.major=''">
            <el-option v-for="(item,index) in studyType.study_type_data" :key="index" :label="item.study_type_value" :value="item.study_type">
          </el-option></el-select>
        </el-form-item>
        <el-form-item label="专业方向" prop="major" v-if="formData.study_type!=null &amp;&amp; formData.study_type!==1">
          <el-select v-model="formData.major" :popper-append-to-body="false" placeholder="请选择专业方向">
            <el-option v-for="(item,index) in major_data" :key="index" :label="item" :value="item">
          </el-option></el-select>
        </el-form-item>
        <el-form-item label="家长姓名">
          <el-input v-model="formData.consignee" maxlength="14" placeholder="请输入家长姓名"></el-input>
        </el-form-item>
        <el-form-item label="家长手机号">
          <el-input v-model="formData.consignee_mobile" maxlength="11" placeholder="请输入家长手机号"></el-input>
        </el-form-item>
        <el-form-item :inline="true" label="家庭住址">
          <div style="display: flex">
            <el-form-item prop="province">
              <el-select v-model="formData.province" :popper-append-to-body="false" class="select" placeholder="请选择省" @change="changeProvince">
                <el-option v-for="(item, index) in province" :key="index" ref="prove" :label="item.address_name" :value="item.address_code"></el-option>
              </el-select>
            </el-form-item>
            -
            <el-form-item prop="city">
              <el-select v-model="formData.city" :popper-append-to-body="false" class="select" placeholder="请选择市" @change="changeCity">
                <el-option v-for="(item, index) in city" :key="index" ref="prove" :label="item.address_name" :value="item.address_code"></el-option>
              </el-select>
            </el-form-item>
            -
            <el-form-item prop="area">
              <el-select v-model="formData.area" :popper-append-to-body="false" class="select" placeholder="请选择区" @change="changeArea">
                <el-option v-for="(item, index) in area" :key="index" ref="prove" :label="item.address_name" :value="item.address_code"></el-option>
              </el-select>
            </el-form-item>
            -
            <el-form-item prop="address">
              <el-input v-model="formData.address" maxlength="30" placeholder="请输入详细地址"></el-input>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item label="实缴金额" prop="payment_amount">
          <el-input v-model="formData.payment_amount" placeholder="请输入实缴金额"></el-input>
        </el-form-item>
        <el-form-item label="缴费时间" prop="pay_time">
          <el-date-picker v-model="formData.pay_time" placeholder="请选择缴费时间" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        </el-form-item>
        <el-form-item label="住宿寝室" prop="goods_standard_id">
          <el-select v-model="formData.goods_standard_id" :popper-append-to-body="false" placeholder="请选择住宿寝室" @change="changeGoodsStandardId">
            <el-option v-for="(item,index) of dormitory" :key="index" :label="item.standard_name" :value="item.goods_standard_id">
          </el-option></el-select>
        </el-form-item>

        <el-form-item ref="img-box" label="缴费凭证">
          <input v-show="false" ref="imgInput" accept="image/png, image/jpeg, image/jpg" multiple="" type="file" @change="addImg">
          <el-button class="replace-img" style="margin-bottom: 12rem;display: flex;align-items: center" @click="btnClick">点击上传</el-button>
          <el-upload :before-upload="imgUploadBefore" :file-list="formData.payment_voucher" :headers="headers" :http-request="uploadImg" :limit="5" :on-preview="imgPreview" :on-remove="imgUploadRemove" accept="image/png, image/jpeg, image/jpg" action="http://www.baidu.com" list-type="picture-card" multiple="" name="image">
            <i class="el-icon-plus"></i>
          </el-upload>
          <p style="color: #999">（请上传5张及以内小于20M的png/jpg格式的图片）</p>
          <el-dialog :visible.sync="dialogVisible">
            <img :src="dialogVisibleUrl" alt="" width="100%">
          </el-dialog>
        </el-form-item>
      </el-form>
    </div>

    <el-dialog :before-close="()=>infoDialogVisible=true" :visible.sync="infoDialogVisible" width="30%">
      <div style="text-align: center">
        <div>{{ textDialogVisible }}</div>
        <el-button style="margin-top: 48rem" type="primary" @click="onInfoDialogVisible">我知道了</el-button>
      </div>
    </el-dialog>
    <div v-show="false">{{ dormitoryArr }}</div>

</edit-template>
</template>
<script>
import uploadImg from '@/components/common/upload.vue'

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  components: {uploadImg},
  data() {
    return {
      loading: false,
      // 表单数据
      formData: {
        school_id: '',
        parent_name: '',
        consignee_mobile: '',
        consignee: '',
        student_type: '',
        grade_name: '',
        grade_year: new Date().getFullYear() + '',
        student_name: '',
        student_idcard: '',
        payment_amount: '',
        pay_time: '',
        standard_name: '',
        goods_standard_id: '',
        province: '',
        city: '',
        area: '',
        address: '',
        detail_address: '',
        payment_voucher: [],
        study_type: null,
        major: '',
      },
      // 表单验证规则
      rules: {
        school_id: [
          {required: true, message: '请选择报名学校', trigger: 'change'}
        ],
        // consignee: [
        //   {required: true, message: '请输入家长姓名', trigger: 'change'},
        //   {min: 1, max: 14, message: '姓名需在14个字符内', trigger: 'change'}
        // ],
        // consignee_mobile: [
        //   {required: true, message: '请输入家长手机号', trigger: 'change'},
        //   {pattern: /^1[23456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'change'},
        // ],
        student_type: [
          {required: true, message: '请选择学生类型', trigger: 'change'}
        ],
        grade_name: [
          {required: true, message: '请选择入学年级', trigger: 'change'}
        ],
        grade_year: [
          {required: true, message: '请选择入学年份', trigger: 'change'}
        ],
        student_name: [
          {required: true, message: '请输入学生姓名', trigger: 'change'},
          {min: 1, max: 14, message: '姓名需在14个字符内', trigger: 'change'}
        ],
        student_idcard: [
          {required: true, message: '请输入学生身份证号', trigger: 'change'},
          {pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X)$)/, message: '请输入15位或18位的身份证号，如最后一位是X请大写', trigger: 'blur'},
        ],
        major: [
          {required: true, message: '请选择专业方向', trigger: 'change'}
        ],
        study_type: [
          {required: true, message: '请选择学习方向', trigger: 'change'}
        ],
        is_training: [
          {required: true, message: '请选择是否为培优生', trigger: 'change'}
        ],
        payment_amount: [
          {required: true, message: '请输入实缴金额', trigger: 'change'},
          {pattern: /^[0-9]+(.[0-9]{1,2})?$/, message: '请输入正确的金额', trigger: 'change'},
        ],
        pay_time: [
          {required: true, message: '请选择选择缴费时间', trigger: 'change'}
        ],
        goods_standard_id: [
          {required: true, message: '请选择住宿寝室', trigger: 'change'}
        ],
        // address: [
        //   {required: true, message: '请输入详细地址', trigger: 'change'},
        // ],
        // province: [
        //   {required: true, message: '请选择省份', trigger: 'change'}
        // ],
        // city: [
        //   {required: true, message: '请选择城市', trigger: 'change'}
        // ],
        // area: [
        //   {required: true, message: '请选择区域', trigger: 'change'},
        // ],
      },
      dialogImageUrl: '',
      dialogVisible: false,
      dialogVisibleUrl: '',
      infoDialogVisible: false,
      textDialogVisible: '',
      // 选择框配置
      selectConfig: {},
      // 寝室
      dormitory: [],
      headers: {
        authorization: 'Bearer ' + sessionStorage.getItem('TOKEN')
      },
      province: [],
      city: [],
      area: [],
      // 学习方向专业方向
      studyType: {
        study_type_data: [],
        major_data: [],
      },
    }
  },

  computed: {
    major_data: function () {
      if (!this.formData.study_type) return []
      return this.studyType.major_data.find(item => item.id === this.formData.study_type).major_type_value
    },
    dormitoryArr: async function () {
      if (this.formData.school_id && this.formData.study_type && this.formData.student_type === 2) {
        return await this.$_register.get('api/recruit-v2/common/goods-standard-rereading', {params: {school_id: this.formData.school_id, study_type: this.formData.study_type,}}).then(res => {
          this.dormitory = res.data.data
          this.formData.goods_standard_id = ''
          this.formData.standard_name = ''
          return res.data.data
        })
      }
      if (this.formData.school_id) {
        return await this.$_register.get('api/recruit-v2/common/goods-standard', {params: {school_id: this.formData.school_id}}).then(res => {
          this.dormitory = res.data.data
          this.formData.goods_standard_id = ''
          this.formData.standard_name = ''
          return res.data.data
        })
      }
    },
  },
  methods: {
    // 选择宿舍
    changeGoodsStandardId(val) {
      this.formData.standard_name = this.dormitory.find(item => item.goods_standard_id === val).standard_name
    },

    // 选择校区
    changeSchool(val) {
      // this.$_register.get('api/recruit-v2/common/goods-standard', {params: {school_id: val}}).then(res => {
      //   this.dormitory = res.data.data
      //   this.formData.goods_standard_id = ''
      //   this.formData.standard_name = ''
      // })
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    //图片上传前的回调
    imgUploadBefore(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 20

      if (!isJPG) {
        this.$message({
          type: 'error',
          message: '上传图片只能是 jpg/png 格式!',
          duration: 2000
        })
      }
      if (!isLt2M) {
        this.$message({
          type: 'error',
          message: '上传图片大小不能超过 20MB!',
          duration: 2000
        })
      }
      return isJPG && isLt2M
    },

    //删除图片回调
    imgUploadRemove(file, fileList) {
      this.formData.payment_voucher = fileList
    },

    //点击图片时的回调
    imgPreview(file) {
      this.dialogVisible = true
      this.dialogVisibleUrl = file.url
    },

    uploadImg(file) {
      let boo = this.imgUploadBefore(file.file)
      if (boo) {
        const loading = this.$loading({
          lock: true,
          text: '上传中',
          customClass: 'img-upload-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$uploadToOSS_1(file.file, 3).then(res => {
          this.formData.payment_voucher.push({
            name: file.file.name,
            url: res
          })
        }).finally(() => {
          loading.close()
        })
      }
    },

    btnClick() {
      this.$refs.imgInput.value = ''
      this.$refs.imgInput.click()
    },

    addImg(e) {
      let list = e.target.files
      let count = 5 - this.formData.payment_voucher.length
      Object.keys(list).forEach(async (item, index) => {
        if (index < count) {
          this.uploadImg({file: list[item]})
        }
      })
    },

    changeProvince(val) {
      this.city = []
      this.area = []
      this.$_register.get('/api/recruit/common/area', {params: {address_code: val}}).then(res => {
        this.city = res.data.data
        this.formData.city = ''
        this.formData.area = ''
        this.formData.address = ''
      })
    },

    changeCity(val) {
      this.area = []
      this.$_register.get('/api/recruit/common/area', {params: {address_code: val}}).then(res => {
        this.area = res.data.data
        this.formData.area = ''
        this.formData.address = ''
      })
    },

    changeArea(val) {
      this.formData.address = ''
    },

    studentChange(val) {
      switch (val) {
        case 1:
          this.formData.grade_name = this.selectConfig.grade_data[0]
          break
        case 2:
          this.formData.grade_name = this.selectConfig.grade_data[1]
          this.dormitory.forEach(item => {
            if (item.standard_name === '8人间') {
              this.formData.goods_standard_id = item.goods_standard_id
            }
          })
          break
      }
    },

    // 表单提交
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.formData.detail_address = ''
          if (this.province.find(item => item.address_code === this.formData.province)) {
            this.formData.detail_address += this.province.find(item => item.address_code === this.formData.province).address_name
          }
          if (this.city.find(item => item.address_code === this.formData.city)) {
            this.formData.detail_address += this.city.find(item => item.address_code === this.formData.city).address_name
          }
          if (this.area.find(item => item.address_code === this.formData.area)) {
            this.formData.detail_address += this.area.find(item => item.address_code === this.formData.area).address_name
          }
          this.formData.detail_address += this.formData.address
          if (this.formData.student_type === 1) {
            let data = {...this.formData}
            if (data.student_type === 1) {
              data.study_type = ''
              data.major = ''
            }
            this.$_register.post('api/recruit-v2/offline/add', data).then(() => {
              this.$message.success('新增成功')
              this.$_register.get('api/recruit-v2/offline/student-prompt', {params: {student_idcard: this.formData.student_idcard}}).then(res => {
                if (res.data.data) {
                  this.infoDialogVisible = true
                  this.textDialogVisible = res.data.data
                } else {
                  this.$router.back()
                  this.$store.commit('setPage', 1)
                }
              })
            })
          } else {
            this.$_register.post('api/recruit-v2/offline/add', this.formData).then(res => {
              this.$message.success('新增成功')
              this.$router.back()
              this.$store.commit('setPage', 1)
            })
          }

        }
      })
    },

    onInfoDialogVisible() {
      this.$router.back()
      this.$store.commit('setPage', 1)
    }
  },

  created() {
    this.$_register.get('api/recruit-v2/offline/config').then(res => {
      this.selectConfig = res.data.data
    })
    this.$_register('/api/recruit/common/area', {params: {address_code: '000000'}}).then(res => {
      this.province = res.data.data
    })
    this.$_register('api/recruit-v2/rereading/screen').then(res => {
      this.studyType = res.data.data
    })
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-form .el-input {
  width: 250rem !important;
}

.zhu-row-line {
  font-size: 14rem;
  line-height: 40rem;
  margin-bottom: 22rem;

  .label {
    color: #666;
    float: left;
    padding-right: 12rem;
    text-align: right;
    vertical-align: middle;
    width: 140rem;
  }
}

::v-deep .el-upload--picture-card {
  display: none;
}

::v-deep .el-dialog__headerbtn {
  display: none;
}

</style>
